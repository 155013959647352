<template>
  <div>
    <div class="font-size-21 font-weight-bold text-dark">
      Hello {{ currentUser.first_name }},
    </div>

    <div class="font-size-36 font-weight-bold text-dark mb-4">
      Welcome back!
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileMixin from "@/mixins/Profile";

export default {
  mixins: [ProfileMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    isApproved() {
      return this.getValueFromSource(this.account, "meta.is_approved", false);
    },
  },
};
</script>
