<template>
  <dashboard-data />
</template>

<script>
import DashboardData from "@/components/facilities/DashboardData.vue";
export default {
  components: {
    DashboardData,
  },
};
</script>
